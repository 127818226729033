.log-actions {
  &__close {
    height: 24px;
    text-align: right;
    cursor: pointer;
    padding-top: 5px;
    padding-right: 5px;

    &:hover {
      svg {
        fill: var(--text-color);
      }
    }

    svg {
      fill: #9d9d9d;
      transition: all .3s;
    }
  }

  &__text {
    color: var(--text-color);
    text-align: center;
    font-size: 18px;

    &.h1 {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 56px;
    height: calc(100% - 120px);
  }

  &__restart,
  &__cancel {
    width: 105px;
  }
}