@import 'styles/variables';

.media-navigation {
  user-select: none;
  cursor: default;
  display: flex;
  align-items: center;
  margin: 12px 0 2px 0;

  font-size: 12px;
  line-height: 14px;
  color: $media-file-border-grey;

  &__path {
    flex-grow: 1;
    text-align: left;
  }

  &__dir {
    transition: all 0.3s;
    cursor: pointer;

    &:hover:not(&--disabled) {
      color: $blue;
    }

    &--disabled {
      cursor: default;
    }
  }

  &__arrows {
    margin-right: 12px;

    > :first-child {
      margin-right: 4px;
    }
  }

  &__arrow {
    transition: all 0.3s;
    svg path {
      fill: var(--icon-btn);
    }
    cursor: pointer;

    &:hover:not(&--disabled) {
      svg path {
        fill: var(--absolute-color-reverse);
      }
    }

    &--disabled {
      cursor: default;
      svg path {
        fill: var(--icon-btn-disabled);
      }
    }
  }

  &__divider {
    margin: 0 4px;
  }
}